<style scoped>
	.dialog_page_footer{text-align: right;}
	.community_box{max-height: 12rem;overflow-y: auto;}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="120px">
				<el-row :gutter="0" style="text-align: center;padding-bottom: 20px;">
					<span>单价为每户/每年 {{unitPrice}} 元</span>
				</el-row>
				<el-row :gutter="0">
					<el-col :span="24">
						<el-form-item label="总容量(户)">
							<el-input-number :disabled="counterDisable" :precision="0" v-model="room_count" @change="numRenew" :min="minRoomNumber" :max="1000000000"
							 label="描述文字"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="0">
					<el-col :span="24">
						<el-form-item label="增加周期(年)">
							<el-input-number :disabled="counterDisable" :precision="0" v-model="cycle" @change="numExpansion" :min="1" :max="100"
							 label="描述文字"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row :gutter="0">
					<el-col :span="24">
						<el-form-item label="合计:">
							<span style="font-size: 1.5rem;color: red;">￥{{allPrice}}</span>
						</el-form-item>
					</el-col>
				</el-row> -->
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即支付</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['params'],
		data() {
			return {
				btnSaveLoading: false,
				counterDisable: false,
				unitPrice: 0,
				allPrice: 0,//总价
				room_count: 1,//容量
				cycle: 1,//周期
				type: 1,
				residence_id: '',//id
				minRoomNumber:1
			}
		},
		created: function() {
			_this = this;
			if(_this.params.residenceService.id){
				_this.minRoomNumber = _this.params.residenceService.room_count;
				_this.room_count = _this.params.residenceService.room_count;
			}
			_this.residence_id = _this.params.id;
			_this.api_getOrderMoney();
		},
		mounted: function() {

		},
		methods: {
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			// _ ** 计数器添加容量
			numRenew(value) {
				_this.room_count = value;
				_this.api_getOrderMoney();
			},
			// _ ** 计数器添加周期
			numExpansion(value) {
				_this.cycle = value;
				_this.api_getOrderMoney();
			},

			// _ ** 计数价格
			api_getOrderMoney() {
				var params = {};
				params.room_count = _this.room_count;
				params.cycle = _this.cycle;
				params.type = _this.type;
				params.residence_id = _this.residence_id;
				_this.counterDisable = true;
				_this._postApi('/wy/residenceServiceOrder/getOrderMoney', params).then((res) => {
					if (res.code == 1) {
						_this.unitPrice = res.data.pt_service_price;
						_this.allPrice = res.data.pay_money;
						_this.counterDisable = false;
					} else {

					}
				}).catch((err) => {})
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = {};
				params.room_count = _this.room_count;
				params.cycle = _this.cycle;
				params.type = _this.type;
				params.residence_id = _this.residence_id;
				_this._postApi('/wy/residenceServiceOrder/getOrderMoney', params).then((res) => {
					if (res.code == 1) {
						_this.unitPrice = res.data.pt_service_price;
						_this.allPrice = res.data.pay_money;
						_this.counterDisable = false;
						_this._postApi('/wy/residenceServiceOrder/create', params).then((ret) => {
							if (res.code == 1) {
								var code_url = ret.data.code_url;
								var order_num = ret.data.out_trade_no;
								_this.$emit('childrenEvent', {
									type: 'openErweima',
									code_url:code_url,
									allPrice:_this.allPrice,
									out_trade_no:order_num
								});
								_this.btnSaveLoading = false;
							} else {
						
							}
						}).catch((err) => {})
					} else {
				
					}
				}).catch((err) => {})
			}
		},
		watch: {
			room_count(val) {
		
			}
		}
	}
</script>
