<style scoped>
	.dialog_page{height: 100%;width: 100%;display: flex;flex-direction: column;}
	.dialog_page_body{flex: 1;}
	.dialog_page_footer{text-align: right;padding-top: 10px;}
</style>

<template>
	<div class="dialog_page">
		<div slot="title">
			<div class="cus_dialog_header">
				<div class="cus_dialog_header_title">{{params.name}} 缴费记录</div>
				<div class="cus_dialog_header_close">
					<span class="el-icon-close" @click="clickCancel"></span>
				</div>
			</div>
		</div>
		<div class="dialog_page_body" id="dialog_page_body">
			<el-table :data="tableData" style="width: 100%" :height="tableHeight" border>
				<el-table-column prop="type_text" label="类型" width=""></el-table-column>
				<el-table-column prop="out_trade_no" label="订单号" width="" :formatter="formatterCellval"></el-table-column>
				<el-table-column prop="pay_money" label="支付金额(元)" width="" :formatter="formatterCellval"></el-table-column>
				<el-table-column prop="pt_service_price" label="单价(每户每年/元)" width=""></el-table-column>
				<el-table-column prop="room_count" label="增加容量(户)" width=""></el-table-column>
				<el-table-column prop="cycle" label="增加周期" width="">
					<template slot-scope="scope">
						<span v-if="scope.row.type==2">{{scope.row.cycle}}天</span>
						<span v-else>{{scope.row.cycle}}年</span>
					</template>
				</el-table-column>
				<el-table-column prop="pay_time" label="付款时间" width=""></el-table-column>
			</el-table>
		</div>
		<div class="dialog_page_footer">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
			 :page-sizes="[10]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	var _this;
	export default{
		props:['params'],
		data(){
			return{
				tableLoading:false,
				tableHeight: 0,
				tableData:[],
				pageNumber:1,
				pageSize:10,
				pageTotal:0,
			}
		},
		created:function(){
			_this = this;
			console.log(_this.params)
			
		},
		mounted:function(){
			window.onresize = function() { 
				//_this.tableHeight = document.getElementById("dialog_page_body").offsetHeight;
			};
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("dialog_page_body").offsetHeight;
				_this.api_getTableData();
			})
		},
		methods:{
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},
			/* * 分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			handleSizeChange(val) {
				
			},
			// _ ** 表格占位
			formatterCellval(row, column, cellValue, index) {
				if (!Boolean(cellValue)) {
					return "--";
				} else {
					return cellValue;
				}
			},
			// _ ** 获取表格数据
			api_getTableData(){
				_this.tableLoading = true;
				_this._getApi('/wy/residenceServiceOrder/getList', {
					residence_id:_this.params.id,
					page: _this.pageNumber,
					limit: _this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						_this.tableData = res.data.list;
						_this.pageTotal = res.data.count;
					} else {
				
					}
				}).catch((err) => {})
			},
		}
	}
</script>
