<style scoped>
	.template {height: 100%;width: 100%;overflow: hidden;position: relative;}
	.page_box {height: 100%;width: 100%;overflow: hidden;display: flex;flex-direction: column;}
	.header_box{padding:10px 5px 5px 10px;}
	.main_box {flex: 1;overflow: hidden;}
	.main_box_content{height: 100%;overflow-y: auto;padding: 0 10px 0 10px;}
	.footer_box{text-align: right;padding: 10px 30px 10px 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
	
	.demo-table-expand {font-size: 0;}
	.demo-table-expand label {width: 90px;color: #99a9bf;}
	.demo-table-expand .el-form-item {margin-right: 0;margin-bottom: 0;width: 50%;}
</style>
<style>
	.el-dialog.erweima_dialog{margin-top: 0;margin-bottom: 0;}
	.el-dialog.erweima_dialog .el-dialog__header{padding: 10px;}
	.el-dialog.erweima_dialog .el-dialog__body{padding: 0;}
	.el-dialog.erweima_dialog .dialog-footer{display: flex;flex-direction: column;align-items: center;}
	.qrcode{
	    display: inline-block;
	    img {width: 132px;height: 132px;background-color: #fff;padding: 6px; box-sizing: border-box;}
	}
	/* 自定义弹窗头 */
	.el-dialog.my_dialog.dialogPayRecord .el-dialog__header{padding: 0!important;}
	.cus_dialog_header{display: flex;align-items: center;}
	.cus_dialog_header_title{flex: 1;font-size: 1.3rem;}
	.cus_dialog_header_close{font-size: 2rem;padding-right: 10px;cursor: pointer;}
</style>
<template>
	<div class="template">
		<div class="page_box">
			<div class="header_box">
				<!-- <el-button type="success" size="small" icon="el-icon-refresh" circle @click="api_gettableList"></el-button> -->
				<el-button type="danger" size="small" @click="api_gettableList">刷新<i class="el-icon-refresh-right"></i></el-button>
			</div>
			<div class="main_box" id="main_box">
				<div class="main_box_content" v-loading="tableLoading">
					<el-table :data="tableList" style="width: 100%" :height="tableHeight" border>
						<el-table-column prop="name" label="小区名" width=""></el-table-column>
						<el-table-column prop="residenceService.room_count" label="房产容量(户)" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="residenceService.end_date" label="服务到期时间" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="residenceService" label="状态" width="">
							<template slot-scope="scope">
								<span v-if="scope.row.residenceService.status==1">{{scope.row.residenceService.status_text}}</span>
								<span style="color: red;" v-else>--</span>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="260">
							<template slot-scope="scope">
								<el-row style="padding-right: 10px;">
									<!-- {{scope.row.residenceService}} -->
									<el-button @click="openRenew(scope.row)" type="text" size="mini">续费</el-button>
									<el-button v-if="scope.row.residenceService.id" @click="openExpansion(scope.row)" type="danger" size="mini">扩容</el-button>
									<el-button v-if="scope.row.residenceService.id" @click="openPayRecord(scope.row)" type="danger" size="mini">缴费记录</el-button>
								</el-row>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="footer_box">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
				 :page-sizes="[10]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
		<!-- 续费弹窗 -->
		<el-dialog custom-class="my_dialog" width="30%" top="none" :title="dialogRenewTitle" :visible.sync="dialogRenew" v-if="dialogRenew"
		 destroy-on-close append-to-body>
			<el-dialog custom-class="erweima_dialog" top="none" width="320px" :visible.sync="dialogErweimaRenew" append-to-body
			 destroy-on-close @close="dialogErweimaClose">
				<div slot="title">合计：<span style="font-size: 1.5rem;">{{allPrice}}</span> 元</div>
				<div style="padding-left: 10px;">请使用微信扫码支付</div>
				<div slot="footer" class="dialog-footer">
					<div class="qrcode" ref="qrCodeUrl"></div>
					<el-button style="width: 100%;margin-top: 20px;" type="success" @click="queryOrder()">我已支付</el-button>
				</div>
			</el-dialog>
			<renew v-if="dialogRenew" :params="selectTableObject" v-on:childrenEvent="handlerEventByEditRenew"></renew>
		</el-dialog>

		<!-- 扩容弹窗 -->
		<el-dialog custom-class="my_dialog" width="30%" top="none" :title="dialogExpansionTitle" :visible.sync="dialogExpansion"
		 v-if="dialogExpansion" destroy-on-close append-to-body>
			<el-dialog custom-class="erweima_dialog" top="none" width="320px" :visible.sync="dialogErweimaExpansion"
			 append-to-body destroy-on-close @close="dialogErweimaClose">
				<div slot="title">合计：<span style="font-size: 1.5rem;">{{allPrice}}</span> 元</div>
				<div style="padding-left: 10px;">请使用微信扫码支付</div>
				<div slot="footer" class="dialog-footer">
					<div class="qrcode" ref="qrCodeUrl"></div>
					<el-button style="width: 100%;margin-top: 20px;" type="success" @click="queryOrder()">我已支付</el-button>
				</div>
			</el-dialog>
			<expansion v-if="dialogExpansion" :params="selectTableObject" v-on:childrenEvent="handlerEventByExpansion"></expansion>
		</el-dialog>

		<!-- 缴费记录 -->
		<el-dialog custom-class="my_dialog dialogPayRecord" width="30%" top="none" :visible.sync="dialogPayRecord"
		 destroy-on-close fullscreen :show-close="false">
			<payment_record v-if="dialogPayRecord" :params="selectTableObject" v-on:childrenEvent="handlerEventByPayRecord"></payment_record>
		</el-dialog>
	</div>
</template>

<script>
	import renew from './renew.vue';
	import expansion from './expansion.vue';
	import payment_record from './payment_record.vue';
	var _this;
	import QRCode from 'qrcodejs2';
	export default {
		components: {
			renew,
			expansion,
			payment_record
		},
		data() {
			return {
				tableHeight: 0,
				tableLoading: false,
				tableList: [],
				pageTotal: 0,
				pageNumber: 1,
				pageSize: 10,
				selectTableObject: null, // 表格操作选取对象
				dialogRenew: false, //续费弹窗
				dialogRenewTitle: '续费',
				dialogExpansion: false, //扩容弹窗
				dialogExpansionTitle: '扩容',
				dialogErweimaRenew: false, //二维码续费弹窗
				dialogErweimaExpansion: false,
				allPrice: '', //付款二维码全款
				dialogPayRecord: false, //缴费记录弹窗
				dialogPayRecordTitle: '缴费记录',
				order_num: '', //订单号
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("main_box").offsetHeight;
				_this.api_gettableList();
			})
		},
		methods: {
			/* * 分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_gettableList();
			},
			handleSizeChange(val) {

			},
			/* * 获取表格数据 */
			api_gettableList() {
				_this.maxIndex = 0;
				// _this.tableLoading = true;
				_this._getApi('/wy/residenceService/getList', {
					page: _this.pageNumber,
					limit: _this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.pageTotal = res.data.count;
						_this.renderCommiunityName(baseData);
					} else {
						_this.tableLoading = false;
					}
				}).catch((err) => {})
			},

			// _ ** 小区表格渲染
			renderCommiunityName(baseData) {
				var params = {};
				if (!baseData[_this.maxIndex].residence_ids) {
					baseData[_this.maxIndex].c_name = '--';
					_this.maxIndex += 1;
					if (_this.maxIndex < baseData.length) {
						_this.renderCommiunityName(baseData);
					} else {
						_this.tableList = baseData;
						_this.tableLoading = false;
					}
				} else {
					var idss = baseData[_this.maxIndex].residence_ids.split(',');
					params.ids = idss;
					_this._postApi('/wy/residence/getInfo', params).then((res) => {
						if (res.code == 1) {
							var baseData1 = res.data;
							var str = '';
							for (var i = 0; i < baseData1.length; i++) {
								if (i == baseData1.length - 1) {
									str += baseData1[i].name + ' ';
								} else {
									str += baseData1[i].name + '、 ';
								}
							}
							baseData[_this.maxIndex].c_name = str;
							_this.maxIndex += 1;
							if (_this.maxIndex < baseData.length) {
								_this.renderCommiunityName(baseData);
							} else {
								
							}
						} else {
							_this.tableLoading = false;
						}
					}).catch((err) => {});
				}
			},

			// _ ** 表格占位
			formatterCellval(row, column, cellValue, index) {
				// console.log(Boolean(cellValue), cellValue);
				if (!cellValue) {
					return "--";
				} else {
					return cellValue;
				}
			},

			// _ ** 打开续费
			openRenew(object) {
				_this.selectTableObject = object;
				_this.dialogRenewTitle = object.name + ' 续费';
				_this.dialogRenew = true;
			},

			// _ ** 打开扩容
			openExpansion(object) {
				_this.selectTableObject = object;
				_this.dialogExpansionTitle = object.name + ' 扩容';
				_this.dialogExpansion = true;
			},

			// _ ** 打开缴费记录
			openPayRecord(object) {
				_this.selectTableObject = object;
				_this.dialogPayRecordTitle = object.name + ' 缴费记录';
				_this.dialogPayRecord = true;
			},

			// _ ** 处理续费角色子组件事件
			handlerEventByEditRenew(e) {
				switch (e.type) {
					case 'close':
						_this.dialogRenew = false;
						break;
					case 'openErweima':
						_this.allPrice = e.allPrice;
						_this.order_num = e.out_trade_no;
						_this.dialogErweimaRenew = true;
						_this.creatQrCode(e.code_url)
						break;
					default:
						break;
				}
			},
			// _ ** 处理续扩容角色子组件事件
			handlerEventByExpansion(e) {
				switch (e.type) {
					case 'close':
						_this.dialogExpansion = false;
						break;
					case 'openErweima':
						_this.allPrice = e.allPrice;
						_this.order_num = e.out_trade_no;
						_this.dialogErweimaExpansion = true;
						_this.creatQrCode(e.code_url)
						break;
					default:
						break;
				}
			},
			// _ ** 处理缴费记录子组件事件
			handlerEventByPayRecord(e) {
				switch (e.type) {
					case 'close':
						_this.dialogPayRecord = false;
						break;
					default:
						break;
				}
			},

			// _ ** 生成二维码
			creatQrCode(code_url) {
				var qrcode = new QRCode(_this.$refs.qrCodeUrl, {
					text: code_url, // 需要转换为二维码的内容
					width: 200,
					height: 200,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
			dialogErweimaClose() {
				_this.api_gettableList();
				_this.dialogExpansion = false;
				_this.dialogRenew = false;
			},

			/**   
			 * 我已支付查询
			 * author:郑凯 
			 * create_time:2021-3-10 10:17:57 
			 */
			queryOrder() {
				_this._getApi('/wy/residenceServiceOrder/queryOrder', {
					out_trade_no: _this.order_num
				}).then((res) => {
					if (res.code == 1) {
						console.log(res)
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							showCancelButton:false,
							type: 'warning'
						}).then(() => {
						
						}).catch(() => {
						
						});
						// if (res.msg == '订单未支付') {
							
						// } else {
						// 	_this.api_gettableList();
						// 	_this.dialogExpansion = false;
						// 	_this.dialogRenew = false;
						// }
					} else {

					}
				}).catch((err) => {})
			}
		}
	}
</script>
